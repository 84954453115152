import Image from "next/image";
import React from "react";
import { productAlt } from "util/common";

function ProductImage({
	product,
	src: propSrc,
	alt: propAlt,
	type: propType,
	className,
	...props
}) {
	const type = propType || product?.product_type || "flower";
	const src =
		propSrc ||
		product?.main_img_url ||
		(type === "oil"
			? "/product-placeholder-oil.svg"
			: "/product-placeholder.svg");
	const alt = propAlt || productAlt(product);
	return (
		<div className={className} {...props}>
			<Image
				src={src}
				alt={alt}
				layout="responsive"
				width={100}
				height={100}
				objectFit="contain"
			/>
		</div>
	);
}

export default ProductImage;
