import janeConfig from "jane.config.js";
import { useState, useEffect } from "react";

function useEnv() {
	const [env, setEnv] = useState("");
	const [url, setUrl] = useState("");

	useEffect(() => {
		const currentEnv = process.env.NEXT_PUBLIC_JANE_ENV || "LOCAL";
		setEnv(currentEnv);

		switch (currentEnv) {
			case "production":
				setUrl(janeConfig.productionUrl);
				break;
			case "staging":
				setUrl(janeConfig.stagingUrl);
				break;
			case "develop":
				setUrl(janeConfig.devUrl);
				break;
			case "LOCAL":
			default:
				setUrl(janeConfig.localUrl);
				break;
		}
	}, []);

	return { env, url };
}

export default useEnv;
