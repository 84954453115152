export const ProductCornerBadge = ({ color, text, variant = "default" }) => {
	const variantClasses = {
		default: "w-[45px] h-[10px] text-[8px] top-[21px] -right-[10px]",
		searchBar: "w-[35px] h-[10px] text-[8px] top-[18px] -right-[7px]",
	};

	return (
		<span
			className={`${color} flex justify-center items-center text-center absolute z-10 origin-top-right rotate-45 font-medium
			 text-white bg-opacity-70 ${variantClasses[variant]}`}
		>
			{text}
		</span>
	);
};

export const ProductOutOfStockBadge = ({ variant = "default" }) => {
	const variantClasses = {
		default: "w-[60px] h-[20px] text-[12px] xs:w-[40px] xs:h-[15px]",
		searchBar: "w-[40px] h-[15px] text-[10px]",
	};

	return (
		<span
			className={`absolute flex justify-center items-center bg-gray-500/60 text-center text-white font-medium rounded-t-md bottom-0 z-10 ${variantClasses[variant]}`}
		>
			אזל
		</span>
	);
};
