import React, { createContext, useContext, useState } from "react";
import { tabs } from "util/types";

export const ActiveTabContext = createContext();

export function ActiveTabProvider({ children }) {
	const [activeTab, setActiveTab] = useState(tabs[0].value); // Set initial active tab

	return (
		<ActiveTabContext.Provider value={{ activeTab, setActiveTab }}>
			{children}
		</ActiveTabContext.Provider>
	);
}
