import { useApi } from "components/hooks/useApi";
import { useRouter } from "next/router";
import { createContext, useEffect, useState } from "react";

const initialState = {
	cities: [],
	stores: [],
	allCities: [],
	marketers: [],
	manufacturers: [],
	strains: [],
	terpenes: [],
	symbols: [],
};

const CacheContext = createContext(initialState);

if (!CacheContext) {
	throw new Error("CacheContext is null");
}

const CacheProvider = ({ children }) => {
	const api = useApi();
	const router = useRouter();
	const [cities, setCities] = useState([]);
	const [allCities, setAllCities] = useState([]);
	const [marketers, setMarketers] = useState([]);
	const [manufacturers, setManufacturers] = useState([]);
	const [strains, setStrains] = useState([]);
	const [terpenes, setTerpenes] = useState([]);
	const [symbols, setSymbols] = useState([]);
	const [manufacturerSeries, setManufacturerSeries] = useState([]);
	const [series, setSeries] = useState([]);
	const [stores, setStores] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [citiesLoaded, setCitiesLoaded] = useState(false);

	function sortByHebName(e) {
		return e?.sort((a, b) =>
			a.heb_name.trim().localeCompare(b.heb_name.trim()),
		);
	}

	useEffect(() => {
		try {
			if (["/logout"].indexOf(router.pathname) !== -1) {
				setLoaded(true);
				return;
			}
			void getCities();
			void load();
		} catch (e) {
			console.log(e);
		}
	}, []);
	const getCities = async () => {
		try {
			const res = await api.getCitiesForFilter();
			if (res.ok) {
				setCities(res.data.items);
				setCitiesLoaded(true);
			}
		} catch (error) {}
	};
	const load = async () => {
		try {
			const res = await api.getObjectsCache();
			if (res.ok) {
				if (res.data?.symbols?.length > 0) {
					const sortedSymbols = res.data?.symbols.map(symbol => {
						if (Array.isArray(symbol.pest)) {
							// Sort the "pest" array alphabetically
							symbol.pest.sort((a, b) => a.heb_name.localeCompare(b.heb_name));
						}
						return symbol;
					});

					// Update the state with the sorted symbols array
					setSymbols(sortedSymbols || []);
				} else {
					setSymbols([]);
				}
				setAllCities(sortByHebName(res.data?.cities) || []);
				setMarketers(sortByHebName(res.data?.marketers) || []);
				setManufacturers(sortByHebName(res.data?.manufacturers) || []);
				setManufacturerSeries(
					sortByHebName(res.data?.manufacturer_series) || [],
				);
				setSeries(sortByHebName(res.data?.series) || []);
				setStrains(sortByHebName(res.data?.strains) || []);
				setTerpenes(sortByHebName(res.data?.terpenes) || []);
				setStores(
					res.data?.stores.sort((a, b) =>
						a.display_name.trim().localeCompare(b.display_name.trim()),
					) || [],
				);
				setLoaded(true);
			}
		} catch (e) {
			console.log(e);
		}
	};
	try {
		return (
			<CacheContext.Provider
				value={{
					cities,
					allCities,
					marketers,
					manufacturers,
					strains,
					stores,
					terpenes,
					symbols,
					manufacturerSeries,
					series,
				}}
			>
				{loaded && citiesLoaded ? (
					children
				) : (
					<div className="mx-auto text-center py-24 text-gray-500">טוען...</div>
				)}
			</CacheContext.Provider>
		);
	} catch (e) {
		console.log(e);
	}
};

export { CacheContext, CacheProvider };
