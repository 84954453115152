import { classNames } from "util/common";

export function Button({
	type = "button",
	primary = false,
	danger = false,
	onClick = () => {},
	disabled = false,
	className,
	children,
	...props
}) {
	return (
		<button
			type={type}
			className={classNames(
				"inline-flex justify-center items-center px-2 py-1 sm:px-2.5 sm:py-1.5 border rounded-md shadow-sm text-xs sm:text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-jane-500",
				!!primary
					? "text-white bg-jane-600 hover:bg-jane-700 border-transparent"
					: !!danger
					? "text-white bg-red-600 hover:bg-red-700 border-transparent focus:ring-red-500"
					: "text-gray-800 bg-white hover:bg-gray-50 border-gray-300",
				!!disabled ? "opacity-50 cursor-not-allowed" : "",
				!!disabled && !primary && !danger
					? "bg-gray-200 hover:bg-gray-200"
					: "",
				className,
			)}
			onClick={onClick}
			disabled={disabled}
			{...props}
		>
			{children}
		</button>
	);
}
