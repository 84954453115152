import dynamic from "next/dynamic";
const DynamicLottie = dynamic(() => import("lottie-react"), { ssr: false });
import React from "react";
import animation from "public/animations/loading-animation.json";
function LoadingAnimation({ width = 100, height = 100, className }) {
	return (
		<DynamicLottie
			animationData={animation}
			style={{ width: width, height: height }}
			className={className}
		/>
	);
}

export default LoadingAnimation;
