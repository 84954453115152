import Head from "next/head";
import Footer from "./Footer";
import HoverTopbar from "./HoverTopbar";

export default function NewLayout({ children }) {
	return (
		<>
			<Head>
				<title>Jane</title>
				<link rel="icon" href="/favicon.ico" />
			</Head>
			<div className="bg-gray-100 flex flex-col min-h-screen justify-between">
				<div className="mb-12 lg:mb-16">
					<HoverTopbar />
				</div>
				<main className="main-width w-full mx-auto px-2 md:px-4">
					{children}
				</main>
				<div className="flex-grow" />
				<Footer />
			</div>
		</>
	);
}
