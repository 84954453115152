import React, { useContext } from "react";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { BsCartPlusFill } from "react-icons/bs";
import { useUserCarts } from "components/jotai/cartAtom";
import Link from "next/link";

export function StoresCartsHeader() {
	const { collectAnalytics } = useContext(AnalyticsContext);
	const { getTotalItemsInAllCarts } = useUserCarts();

	return (
		<div className="flex justify-center items-center h-14 lg:h-16">
			<div className="bg-white flex items-center z-50">
				<Link href="/cart">
					<a
						className="cursor-pointer"
						onClick={() => {
							collectAnalytics("cart_opened");
						}}
					>
						<div className="relative flex justify-center items-center gap-1">
							<div>
								<BsCartPlusFill className="w-6 h-6" />
								{getTotalItemsInAllCarts() > 0 && (
									<span
										className="absolute flex justify-center items-center w-4 h-4 -top-1 -right-1.5 bg-red-500
        							  text-red-50 font-bold text-[11px] rounded-full ml-1"
									>
										{getTotalItemsInAllCarts()}
									</span>
								)}
							</div>
							<span className="font-medium text-sm sm:text-base lg:flex hidden">
								הסל שלי
							</span>
						</div>
					</a>
				</Link>
			</div>
		</div>
	);
}
