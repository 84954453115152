import { GoogleLogin } from "@react-oauth/google";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { AuthContext } from "components/contexts/AuthContext";
import { useApi } from "components/hooks/useApi";
import React, { useContext } from "react";
import { toastMessage } from "util/common";

const GoogleButton = ({ setError, onLogin = () => {} }) => {
	const api = useApi();
	const { collectAnalytics } = useContext(AnalyticsContext);
	const { checkLogin } = useContext(AuthContext);

	return (
		<div className="flex items-center justify-center w-full">
			<GoogleLogin
				logo_alignment="center"
				size="large"
				theme="outline"
				locale="he"
				onSuccess={async credentialResponse => {
					const token = credentialResponse.credential;
					const res = await api.submitGoogleLogin({ token });
					if (res.ok) {
						collectAnalytics("end_user_login", { method: "google" });
						checkLogin();
						toastMessage(`התחברת לחשבונך בהצלחה`, "success");
						onLogin();
					} else {
						setError(res?.error?.message);
					}
				}}
				onError={() => {
					console.log("Login Failed");
				}}
			/>
		</div>
	);
};

export default GoogleButton;
