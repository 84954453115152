import { FieldError } from "components/form/FieldError";
import React from "react";
import { classNames } from "util/common";
import { NAME_REGEX } from "./util/fieldsRegex";

function NameField({
	register,
	errors,
	disabled = false,
	registerName = "first_name",
	placeholder = "שם פרטי",
	required = true,
	label = "שם פרטי",
	hideLabel = false,
	additionalValidation,
	isUpdated = false,
	name = "firstName",
	className,
}) {
	return (
		<div className={className}>
			{!hideLabel && (
				<label
					htmlFor={registerName}
					className="block text-base font-medium text-gray-700"
				>
					{label}
				</label>
			)}
			<input
				type="text"
				id={registerName}
				name={name}
				className={classNames(
					"mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-base focus:ring-1 disabled:cursor-not-allowed",
					!!errors?.[registerName]
						? "border-red-300 focus:ring-red-500 focus:border-red-500 bg-red-50 bg-opacity-50"
						: "border-gray-300 focus:ring-jane-500 focus:border-jane-500",
					disabled && "bg-jane-100",
					isUpdated?.[registerName] && "border-jane-500 bg-jane-300",
				)}
				placeholder={placeholder}
				{...register(registerName, {
					required: required ? "שדה חובה" : required,
					minLength: {
						value: 2,
						message: "ערך לא תקין",
					},
					maxLength: {
						value: 36,
						message: "ערך לא תקין",
					},
					pattern: {
						value: NAME_REGEX,
						message: "ערך לא תקין",
					},
					...additionalValidation, // Additional custom validation rules
				})}
				disabled={disabled}
				autoComplete="off"
			/>
			{errors?.[registerName] && (
				<FieldError message={errors[registerName].message} />
			)}
		</div>
	);
}

export default NameField;
