import React, { useCallback } from "react";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "util/common";

export function Modal({
	children,
	isOpen = false,
	activator = <></>,
	onChange = isOpen => {},
	closeOnClickOutside = false,
	onClose: onCloseDialog,
	maxWidth,
}) {
	const [open, setOpen] = useState(isOpen);
	useEffect(() => setOpen(isOpen), [isOpen]);
	useEffect(() => onChange(open), [open]);

	const onClose = useCallback(() => {
		if (!!closeOnClickOutside) {
			setOpen(false);
			onCloseDialog();
		}
	}, [closeOnClickOutside]);

	maxWidth = maxWidth || 600;

	return (
		<>
			{typeof activator === "function" ? (
				activator({ setOpen })
			) : (
				<span onClick={() => setOpen(true)}>{activator}</span>
			)}

			{!!open && (
				<Transition.Root show={open} as={Fragment}>
					<Dialog
						as="div"
						static
						className="fixed z-50 inset-0 overflow-y-auto"
						open={open}
						onClose={onClose}
					>
						<div className="flex items-center justify-center min-h-screen text-center sm:block">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
							</Transition.Child>

							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div
									className="inline-block align-bottom bg-white rounded-lg text-start shadow-xl transform transition-all sm:my-8 sm:align-middle w-full mx-2.5 sm:mx-0"
									style={{
										maxWidth,
									}}
								>
									<div>
										{typeof children === "function"
											? children({ setOpen })
											: children || <button />}
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
			)}
		</>
	);
}
