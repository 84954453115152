import { getCookie } from "util/common";
import { apiServerUrl } from "jane.config";
import { useRouter } from "next/router";
import { userDocTypes, userFoldersByDocumentType } from "util/types";
import { AuthContext } from "components/contexts/AuthContext";
import { useContext } from "react";

export function useHttp() {
	const router = useRouter();
	const { claims } = useContext(AuthContext);

	const $get = async url => {
		return await sendHttpRequest(url, {}, unauthorizedCallback, router);
	};

	const $post = async (url, params = {}) => {
		const opts = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"X-CSRFToken": getCsrfToken(),
			},
			body: JSON.stringify(params),
		};
		return await sendHttpRequest(url, opts, unauthorizedCallback, router);
	};

	const $upload = async (file, storageName, documentType = null) => {
		if (!file || !storageName) {
			throw new Error(`$upload: missing arguments.`);
		}
		const fd = new FormData();
		fd.append("file", file);
		fd.append("document_type", documentType);

		// Specify folder for user documents
		if (documentType in userDocTypes) {
			fd.append(
				"prefix",
				`jane/${claims.id}/${userFoldersByDocumentType[documentType]}/`,
			);
		}

		const url = `${apiServerUrl}/api/upload/${storageName}/`;
		const opts = {
			method: "POST",
			headers: { "X-CSRFToken": getCsrfToken() },
			body: fd,
		};
		return await sendHttpRequest(url, opts, unauthorizedCallback, router);
	};

	const unauthorizedCallback = async () => {
		if (router.pathname.includes("/account")) {
			return;
		}
		router.push("/logout/");
	};

	return {
		$get,
		$post,
		$upload,
	};
}

async function sendHttpRequest(
	url,
	opts = {},
	unauthorizedCallback = () => {},
	router,
) {
	opts = {
		...opts,
		credentials: "include",
		headers: { ...(opts.headers || {}), "X-Origin-Page": window.location.href },
	};
	let res;
	try {
		res = await fetch(url, opts);
	} catch (err) {
		return {
			ok: false,
			error: err,
		};
	}
	if (!!res.ok) {
		return normalizeResponse(res, router);
	}

	if (res.status === 401) {
		unauthorizedCallback();
	}

	const title = `שגיאה ${res.status || ""}`;
	const message = res.statusText || "שגיאה לא מוכרת.";
	return {
		ok: false,
		error: { title, message },
	};
}

async function normalizeResponse(resp, router) {
	let respJson = {};
	try {
		respJson = await resp.json();
	} catch (err) {
		return {
			ok: false,
			resp,
		};
	}

	if (respJson?.status === "OK") {
		return { ok: true, data: respJson.data || {} };
	} else {
		const isStoresOrProductsRoute =
			router.pathname.includes("/stores/") ||
			router.pathname.includes("/products/");

		if (respJson?.error?.code === "404" && isStoresOrProductsRoute) {
			router.push("/404");
		}
		return { ok: false, error: respJson.error || {} };
	}
}

const getCsrfToken = () => getCookie("csrftoken");
