import moment from "moment";

export const checkIfDatePassed = dateStringOrTimestamp => {
	const startOfToday = new Date().setUTCHours(0, 0, 0, 0);
	const dateTimestamp = new Date(dateStringOrTimestamp).getTime();
	return startOfToday > dateTimestamp;
};

export const isNewInStore = storeAddTime => {
	const addedDate = moment(storeAddTime, "YYYYMMDD");
	const daysDifference = moment().diff(addedDate, "days");
	const threeMonths = 90;

	return daysDifference <= threeMonths;
};

export const isComingSoon = createdAt => {
	return moment().diff(moment(createdAt, "YYYYMMDD"), "days") <= 90;
};
