import { ChevronUpIcon } from "@heroicons/react/solid";
import React, { useState, useEffect } from "react";

const BackToTop = () => {
	const [isVisible, setIsVisible] = useState(false);

	const handleScroll = () => {
		const scrollY = window.scrollY;
		setIsVisible(scrollY > 600);
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div
			className={`cursor-pointer fixed bottom-[52px] z-[999] bg-white border-black border-2 p-2 rounded-full transition-opacity duration-300 ease-in-out ${
				isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
			}`}
			onClick={scrollToTop}
		>
			<ChevronUpIcon className="w-5 h-5" />
		</div>
	);
};

export default BackToTop;
