import { useState, useEffect } from "react";

const EXPIRATION_IN_MINUTES = 10;

const useLocalStorage = key => {
	const [storageKey, setStorageKey] = useState(null);

	useEffect(() => {
		try {
			if (!key) return;
			setStorageKey(key);
			const item = localStorage.getItem(key);
			if (!item) {
				localStorage.setItem(
					key,
					JSON.stringify({ value: {}, timestamp: Date.now() }),
				);
			} else {
				const data = JSON.parse(item);
				if (Date.now() - data.timestamp > EXPIRATION_IN_MINUTES * 60 * 1000) {
					localStorage.removeItem(key);
				}
			}
		} catch (error) {
			console.error(`An error occurred: ${error}`);
		}
	}, []);

	const saveToLocalStorage = (objKey, value) => {
		const item = getFromLocalStorage();
		try {
			let retrievedObj = { ...item };
			retrievedObj[objKey] = value;
			localStorage.setItem(
				key,
				JSON.stringify({ value: retrievedObj, timestamp: Date.now() }),
			);
		} catch (error) {
			console.error(`An error occurred: ${error}`);
		}
	};

	const getFromLocalStorage = objKey => {
		try {
			const item = localStorage.getItem(key);
			if (item) {
				const data = JSON.parse(item);
				if (Date.now() - data.timestamp > EXPIRATION_IN_MINUTES * 60 * 1000) {
					localStorage.removeItem(key);
					return null;
				} else {
					if (!!objKey) {
						return data.value[objKey];
					} else {
						return data.value;
					}
				}
			}
			return null;
		} catch (error) {
			console.error(`An error occurred: ${error}`);
		}
	};

	const clearLocalStorage = () => {
		try {
			localStorage.removeItem(storageKey);
		} catch (error) {
			console.error(`An error occurred: ${error}`);
		}
	};

	return {
		getFromLocalStorage,
		saveToLocalStorage,
		clearLocalStorage,
	};
};

export default useLocalStorage;
