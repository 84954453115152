import Link from "next/link";

export default function Footer() {
	return (
		<footer className="bg-white border-t border-jane-200">
			<div className="main-width mx-auto py-10 px-4 flex flex-col gap-4">
				<div className="md:flex md:items-center md:justify-between">
					<div className="md:w-1/3 md:order-1">
						<p className="text-center md:text-left text-base text-jane-400">
							כל הזכויות שמורות - Jane - {new Date().getFullYear()} &copy;
						</p>
					</div>
					<div className="text-jane-400 flex items-center justify-center mt-1 md:w-1/3 md:justify-start md:mt-0 sm:text-sm lg:text-base">
						<Link href="/terms">
							<a
								rel="noopener noreferrer"
								className="hover:underline cursor-pointer hover:text-jane-500"
							>
								תנאי שימוש
							</a>
						</Link>
						<div className="mx-2 text-jane-300">|</div>
						<Link href="/privacy">
							<a
								rel="noopener noreferrer"
								className="hover:underline cursor-pointer hover:text-jane-500"
							>
								מדיניות פרטיות
							</a>
						</Link>
						<div className="mx-2 text-jane-300">|</div>
						<Link href="/accessibility">
							<a
								rel="noopener noreferrer"
								className="hover:underline cursor-pointer hover:text-jane-500"
							>
								נגישות
							</a>
						</Link>
						<div className="mx-2 text-jane-300">|</div>
						<a
							href="mailto:info@jane.co.il"
							rel="noopener noreferrer"
							className="hover:underline cursor-pointer hover:text-jane-500"
						>
							צור קשר
						</a>
					</div>
				</div>
				<div className="text-center text-jane-400 text-sm md:text-start md:flex-row-reverse md:justify-between flex items-center gap-1 flex-col">
					<div className="flex gap-2 items-center md:w-1/2 md:justify-end">
						<a href="https://www.iso.org/standard/62777.html">
							<img
								className="w-14 h-14"
								alt="ISO-27799"
								src="https://cdn.jane.co.il/general/iso-27799.jpg"
							/>
						</a>
						<a href="https://www.iso.org/standard/27001">
							<img
								className="w-14 h-14"
								alt="ISO-27001"
								src="https://cdn.jane.co.il/general/iso-27001.jpeg"
							/>
						</a>
						<a href="https://www.sii.org.il/he/iso-9001">
							<img
								className="w-14 h-14"
								alt="ISO-9001"
								src="https://cdn.jane.co.il/general/iso-9001.jpeg"
							/>
						</a>
						<a href="https://www.gov.il/he/departments/units/cannabis_unit/govil-landing-page">
							<img
								className="w-14 h-14"
								alt="IMCA"
								src="https://cdn.jane.co.il/general/imca.png"
							/>
						</a>
					</div>
					<p className="md:w-1/2">
						המידע המוצג באתר זה נועד לשמש כסקירה כללית ולא כייעוץ מחייב. יש
						להימנע מלהסתמך עליו כתחליף לייעוץ רפואי מקצועי. אין לקבל את התוכן
						באתר זה כפרסום, המלצה או קריאה לשימוש בקנאביס למטרות שאינן רפואיות,
						המהווה הפרה של החוק. שימוש בקנאביס רפואי צריך להתבצע בהמלצת רופא
						מומחה, בהתאם לרישיון נתון ממשרד הבריאות ולאחר קבלת הנחייה מקדימה,
						כפי שנדרש על פי החוק.
					</p>
				</div>
			</div>
		</footer>
	);
}
