import React from "react";
import { classNames } from "util/common";
import { optOnlyMobile } from "../../util/types";
import { FieldError } from "components/form/FieldError";

export function PhoneField({
	label = "מספר טלפון",
	placeholder = "נייח \\ נייד...",
	phoneNumberField = "phone",
	prePhoneNumberField = "prefix_phone",
	register,
	watch = _ => false,
	errors,
	required = false,
	otherUniqueNumber = undefined,
	otherPrefixNumber = undefined,
	trigger,
	hideLabel = false,
	disabled = false,
	className = "",
}) {
	const secondNumberValidation = value => {
		if (
			value &&
			value === watch(otherUniqueNumber) &&
			watch(otherPrefixNumber) === watch(prePhoneNumberField)
		) {
			return "מספר הטלפון כבר בשימוש"; // Return an error message
		}
		return true; // Validation passed
	};

	return (
		<div className={"col-span-12 sm:col-span-6 " + className}>
			{!hideLabel && (
				<label
					htmlFor={phoneNumberField}
					className="text-base flex items-center gap-2 font-medium text-gray-700"
				>
					{label}
				</label>
			)}

			<div className="flex items-center">
				<input
					type="number"
					className={classNames(
						"mt-1 rounded-r-lg w-9/12 border shadow-sm py-2 px-3 focus:outline-none sm:text-base",
						errors?.[phoneNumberField]
							? "border-red-300 focus:ring-red-500 focus:border-red-500 bg-red-50 bg-opacity-50"
							: "border-gray-300 focus:ring-jane-500 focus:border-jane-500",
						disabled && "bg-gray-100 cursor-not-allowed",
						className,
					)}
					placeholder={placeholder}
					{...register(phoneNumberField, {
						required: {
							value: required || !!watch(prePhoneNumberField),
							message: required
								? "אנא הזן/י מספר טלפון"
								: "אנא הזן/י מספר טלפון או הסר/י קידומת",
						},
						maxLength: { value: 7, message: "אנא הכנס/י מספר טלפון תקין" },
						minLength: { value: 7, message: "אנא הכנס/י מספר טלפון תקין" },
						pattern: {
							value: /^[0-9]*$/,
							message: "אנא בדוק/י את מספר הטלפון שוב",
						},
						validate:
							otherUniqueNumber !== undefined //Validate only second phone number field
								? secondNumberValidation
								: undefined,
					})}
					autoComplete="off"
					disabled={disabled}
					onBlur={e => {
						trigger(prePhoneNumberField);
						trigger(phoneNumberField); //sometimes validation don't work...
					}}
				/>
				<select
					disabled={disabled}
					className={classNames(
						"form-select w-3/12 mt-1 custom-scroll border-gray-300  rounded-l-lg pr-2 font-semibold  pointer ease-in-out  rich-select focus:border-gray-500 focus:ring-jane-500",
						disabled && "bg-gray-100 cursor-not-allowed",
					)}
					{...register(prePhoneNumberField, {
						required: required || !!watch(phoneNumberField),
					})}
					onBlur={e => {
						trigger(prePhoneNumberField); //sometimes validation don't work...
						trigger(phoneNumberField);
					}}
				>
					{optOnlyMobile.map(o => (
						<option key={o.value} value={o.value}>
							{o.label}
						</option>
					))}
				</select>
			</div>
			{errors?.[prePhoneNumberField] && (
				<FieldError message="קידומת לא תקינה" />
			)}
			{errors?.[phoneNumberField] && (
				<FieldError message={errors[phoneNumberField].message} />
			)}
		</div>
	);
}
