import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import { Button } from "components/form/Button";
import OtpLoginCard from "components/login/OtpLoginCard";
import { ModalDialog } from "components/modals/ModalDialog";
import React, { useState } from "react";

export function LoginBoxModal({ activator }) {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<ModalDialog
			activator={activator || defaultActivator}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			title="כניסה לחשבון מטופל"
			onSubmit={() => setIsOpen(false)}
			showSubmitButton={false}
			cancelBtnText="סגירה"
		>
			<OtpLoginCard onLogin={() => setIsOpen(false)} />
		</ModalDialog>
	);
}

const defaultActivator = (
	<Button primary>
		החשבון שלי
		<ArrowSmLeftIcon className="w-4 h-4 mr-1" />
	</Button>
);
