export const singlePageTypes = {
	"/widget/products/store/[id]": "store",
	"/widget/stores/product/[id]": "product",
};

export const clickEvents = {
	store_tab_click: {
		field: "tab",
	},
	external_click: {
		field: "link_type",
	},
	table_layout_change: {
		field: "layout",
	},
};
