import { useEffect, useState } from "react";

const inIframe = () => {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
};

export const useIsInIframe = () => {
	const [isInIFrame, setIsInIFrame] = useState(null);

	useEffect(() => {
		setIsInIFrame(inIframe());
	}, []);

	return isInIFrame;
};
