import { hasCharactersAfterStringInURL } from "util/common";
import { clickEvents } from "./constants";

const ID_PREFIX_SEPARATOR = "__";

export const handleClickEvent = (e, collectAnalytics) => {
	// id of the element should be __store_tab_click__sales for example => sales is the value (sales tab was clicked)
	let elementId = findParentWithId(e.target, ID_PREFIX_SEPARATOR);
	if (!elementId) {
		return;
	}
	const eventId = elementId.slice(2);
	const splittedEventId = eventId.split(ID_PREFIX_SEPARATOR);
	const eventName = splittedEventId[0];
	const eventPayload = {};
	if (splittedEventId.length > 1) {
		// event ID has a value associate with
		const eventValue = splittedEventId[1];
		const eventData = clickEvents[eventName];
		if (eventData) {
			eventPayload[eventData.field] = eventValue;
		}
	}
	if (!eventName) {
		return;
	}
	collectAnalytics(eventName, eventPayload);
};

const findParentWithId = (
	clickedElementTarget,
	desiredIdPrefix,
	maxDepth = 4,
) => {
	// pass maxDepth -1 for entire DOM search
	let node = clickedElementTarget;
	let counter = maxDepth;
	while (node) {
		if (counter === 0) {
			return;
		}
		if (node.id.startsWith(desiredIdPrefix)) {
			return node.id;
		}
		node = node.parentElement;
		counter -= 1;
	}
};

export const getEventScopeFromUrl = (path, row, widgetActiveTab = null) => {
	const storesScope = ["/stores/sales/", "/stores/avg-discounts/", "/stores/"];

	const isSingleProductPage =
		path.startsWith("/products/") && path !== "/products/";

	const isSingleProductPageWidget = path.startsWith("/widget/stores/product/");

	if (
		path === "/widget/home/" &&
		(widgetActiveTab === "byStore" || widgetActiveTab === "byDiscounts")
	) {
		return "stores";
	}
	if (
		storesScope.includes(path) ||
		isSingleProductPage ||
		isSingleProductPageWidget
	) {
		return "stores";
	} else {
		return "products";
	}
};

export const getPayloadFromExpandedRow = (
	row,
	path,
	widgetActiveTab = null,
) => {
	const eventScope = getEventScopeFromUrl(path, row, widgetActiveTab);
	let eventPayload;
	let eventName;
	switch (eventScope) {
		case "products":
			// product row expanded
			eventName = "product_row_expanded";
			eventPayload = {
				product_id: row.id,
				...(row.store_id && {
					store_id: row.store_id,
					display_name: row?.display_name,
				}),
			};
			break;
		case "stores":
			eventName = "pharmacy_row_expanded";
			eventPayload = {
				store_id: row.id,
				display_name: row.display_name,
			};
			break;
	}
	return { eventName, eventPayload };
};

export const getStoresEventScopeFromUrl = (
	path,
	widgetActiveTab = null,
	isInIframe = false,
) => {
	//Widget/home iframe
	if (path === "/widget/home/" && widgetActiveTab === "byStore") {
		return { scope: "stores", website: "stores iframe" };
	}

	//Jane
	if (/^\/stores\/avg-discounts\/.*/.test(path)) {
		return { scope: "stores", website: "avg-discounts" };
	}
	if (/^\/stores\/\?.+/.test(path)) {
		// stores with filter
		return { scope: "stores", website: "stores" };
	}
	if (/^\/stores\/$/.test(path)) {
		// stores page exactly
		return { scope: "stores", website: "stores" };
	}
	if (/^\/desired-cart\/.*/.test(path)) {
		return { scope: "stores", website: "Jane desired cart" };
	}
	if (/^\/products\/\w.*/.test(path)) {
		// single product page jane
		return { scope: "stores", website: "Jane product" };
	}
	if (/^\/cities\/\w.*/.test(path)) {
		return { scope: "stores", website: "cities" };
	}
	if (/^\/widget\/stores\/product\/\w.*/.test(path)) {
		if (isInIframe) {
			return { scope: "stores", website: "iframe in cannabiz product" };
		}
		return { scope: "stores", website: "Jane widget product" };
	}

	return null;
};

export const getPharmacyRowExpandedGoogleEvent = (
	row,
	path,
	widgetActiveTab = null,
	isInIframe = false,
) => {
	const eventScope = getStoresEventScopeFromUrl(
		path,
		widgetActiveTab,
		isInIframe,
	);
	if (!eventScope) {
		return;
	}

	if (eventScope.website === "cities") {
		//  we dont want to track cities products page - only case in all website
		return;
	}

	if (
		[
			"sales",
			"discounts",
			"stores",
			"Jane desired cart",
			"stores iframe",
			"sales iframe",
			"avg-discounts",
		].includes(eventScope.website)
	) {
		const affiliation = `${row?.display_name} - ${row?.city?.heb_name}`;
		const event = {
			event: "select_item",
			ecommerce: {
				items: [
					{
						affiliation: affiliation,
						item_name: "none",
						item_id: "none",
						item_list_name: eventScope.website,
						website: eventScope.website,
					},
				],
			},
		};
		return event;
	} else {
		// Single store pages
		const affiliation = `${row?.display_name} - ${row.city?.heb_name}`;
		const id = row.product_id;
		const itemName = `${row?.product?.heb_name} ${row?.product?.eng_name}`;
		const event = {
			event: "select_item",
			ecommerce: {
				items: [
					{
						affiliation: affiliation,
						item_name: itemName,
						item_id: id,
						item_list_name: "item page",
						website: eventScope.website,
					},
				],
			},
		};
		return event;
	}
};

export const getPharmacyImpressionGoogleEvent = (
	path,
	widgetActiveTab,
	rows,
	isInIframe,
) => {
	const eventScope = getStoresEventScopeFromUrl(
		path,
		widgetActiveTab,
		isInIframe,
	);
	if (!eventScope) {
		return;
	}

	if (
		[
			"sales",
			"discounts",
			"stores",
			"Jane desired cart",
			"stores iframe",
			"sales iframe",
			"cities",
			"avg-discounts",
		].includes(eventScope.website)
	) {
		const isCitysProductRows = rows.some(row => row?.catalog_price);
		if (eventScope.website === "cities" && isCitysProductRows) {
			return;
		}

		const eventItems = rows.map(store => {
			return {
				affiliation: store?.display_name + " - " + store.city?.heb_name,
				item_name: "none",
				item_id: "none",
				item_list_name: eventScope.website,
				website: eventScope.website,
			};
		});

		const event = {
			event: "view_item_list",
			ecommerce: {
				items: eventItems,
			},
		};
		return event;
	} else {
		const eventItems = rows.map(store => {
			const affiliation = `${store?.display_name} - ${store?.city?.heb_name}`;
			const id = store?.product_id;
			const itemName = `${store?.product?.heb_name} ${store?.product?.eng_name}`;
			return {
				affiliation: affiliation,
				item_name: itemName,
				item_id: id,
				item_list_name: "item page",
				website: eventScope.website,
			};
		});

		const event = {
			event: "view_item_list",
			ecommerce: {
				items: eventItems,
			},
		};
		return event;
	}
};

export const getPharmacyPageViewGoogleEvent = (store, website) => {
	const affiliation = `${store?.display_name} - ${store.city?.heb_name}`;
	const event = {
		event: "view_item_list",
		ecommerce: {
			items: [
				{
					affiliation: affiliation,
					item_name: "none",
					item_id: "none",
					item_list_name: "store page",
					website: website,
				},
			],
		},
	};
	return event;
};

export const generateBrowserFingerprint = (salt = "") => {
	const userAgent = window.navigator.userAgent;
	const timezoneOffset = new Date().getTimezoneOffset();
	const language = window.navigator.userLanguage || window.navigator.language;
	const currentTimeInMinutes = Math.floor(Date.now() / 60000);

	const fingerprintData = [
		userAgent,
		timezoneOffset,
		language,
		currentTimeInMinutes,
		salt,
	].join("*");

	// Create the CRC32 lookup table
	const crc32Table = new Array(256);
	for (let i = 0; i < 256; i++) {
		let value = i;
		for (let j = 0; j < 8; j++) {
			value = value & 1 ? (value >>> 1) ^ 0xedb88320 : value >>> 1;
		}
		crc32Table[i] = value;
	}

	// Calculate the CRC32 hash
	let crc = 0xffffffff;
	for (let i = 0; i < fingerprintData.length; i++) {
		const charCode = fingerprintData.charCodeAt(i);
		crc = (crc >>> 8) ^ crc32Table[(crc ^ charCode) & 0xff];
	}

	// Finalize the hash and convert it to a base-36 string
	const fingerprint = ((crc ^ 0xffffffff) >>> 0).toString(36);

	return fingerprint;
};

// Function to hash the cookie value using custom Base64-like encoding
export const hashCookieValue = value => {
	// Define the custom character set for encoding
	const customBase64Chars =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.";

	// Convert the input to a string if it isn't already
	const inputString = String(value);

	// Initialize an array to store encoded characters
	const encodedChars = [];

	// Process the input string in chunks of 3 characters (3 bytes)
	for (let i = 0; i < inputString.length; i += 3) {
		// Get character codes for up to 3 characters at a time
		const charCode1 = inputString.charCodeAt(i);
		const charCode2 =
			i + 1 < inputString.length ? inputString.charCodeAt(i + 1) : 0;
		const charCode3 =
			i + 2 < inputString.length ? inputString.charCodeAt(i + 2) : 0;

		// Use bitwise operations to create four 6-bit values from the three bytes
		const enc1 = charCode1 >> 2;
		const enc2 = ((charCode1 & 3) << 4) | (charCode2 >> 4);
		const enc3 = ((charCode2 & 15) << 2) | (charCode3 >> 6);
		const enc4 = charCode3 & 63;

		// Adjust for padding if fewer than 3 characters remain
		if (i + 1 >= inputString.length) {
			// Only 1 character left
			encodedChars.push(
				customBase64Chars[enc1],
				customBase64Chars[enc2],
				".",
				".",
			);
		} else if (i + 2 >= inputString.length) {
			// Only 2 characters left
			encodedChars.push(
				customBase64Chars[enc1],
				customBase64Chars[enc2],
				customBase64Chars[enc3],
				".",
			);
		} else {
			// All 3 characters present
			encodedChars.push(
				customBase64Chars[enc1],
				customBase64Chars[enc2],
				customBase64Chars[enc3],
				customBase64Chars[enc4],
			);
		}
	}

	// Join the array of characters into a single encoded string
	return encodedChars.join("");
};

export const getCookieByName = name => {
	// Search for the cookie name followed by "=" and capture its value until the next ";" (if any)
	const match = document.cookie.match(new RegExp(`${name}=([^;]+)`));

	if (match) {
		// If a match is found, attempt to extract the Google Analytics ID pattern (GA1.<version>.<id> or GS1.<version>.<id>)
		const gaMatch = match[1].match(/(GA1|GS1)\.[0-9]\.(.+)/);
		return gaMatch ? gaMatch[2] : undefined; // Return the ID part if matched, otherwise undefined
	}

	return undefined; // Return undefined if the cookie is not found
};

export const getGACrossDomainParams = () => {
	const cookies = {
		_ga: getCookieByName("_ga"),
		_gac: undefined,
		_gid: getCookieByName("_ga_X9CR1937YZ"),
	};

	return [
		"1",
		generateBrowserFingerprint(),
		"_ga",
		hashCookieValue(cookies._ga),
		"_ga_X9CR1937YZ",
		hashCookieValue(cookies._gid),
	].join("*");
};
