import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from "react";
import { AuthContext } from "./AuthContext";
import { handleClickEvent } from "../../util/analytics";
import { useIsInIframe } from "../hooks/useIsInIframe";
import mixpanel from "mixpanel-browser";
import { useRouter } from "next/router";
import { isIncognitoMode } from "util/common";

const AnalyticsContext = createContext();

const AnalyticsProvider = ({ children }) => {
	const { claims } = useContext(AuthContext);
	const isInIframe = useIsInIframe();
	const isProd = process.env.NEXT_PUBLIC_JANE_ENV === "production";
	const isStage = process.env.NEXT_PUBLIC_JANE_ENV === "staging";
	const mixpanelKey = process.env.NEXT_PUBLIC_MIXPANEL_PUBLIC_KEY;
	const SHOULD_TRACK =
		(isProd || isStage) && !!mixpanelKey && !isIncognitoMode();

	const router = useRouter();

	const shouldTrack = useMemo(() => {
		return SHOULD_TRACK;
	}, []);

	useEffect(() => {
		mixpanel.init(mixpanelKey, {
			track_pageview: false,
		});
	}, []);

	useEffect(() => {
		if (shouldTrack) {
			mixpanel.track_pageview();
		}
	}, [router.pathname]);

	const collectAnalytics = useCallback(
		(eventName, data = {}) => {
			// this function is a wrapper for mixpanel's track function
			if (!shouldTrack) {
				return;
			}
			if (!eventName) {
				// event is not defined - don't fire
				return;
			}
			mixpanel.track(eventName, data);
		},
		[claims?.email, isInIframe],
	);

	const collectAnalyticsGoogle = event => {
		window.dataLayer = window.dataLayer || [];
		// EVENT SCHEMA
		// {
		// 	event: "view_item",
		// 	ecommerce: {
		// 		items: [
		// 			{
		// 				item_name: "סונאטה Sonata",
		// 				item_id: "sonata",
		// 				price: "259.00",
		// 				currency: "ILS",
		// 				item_brand: "סבן",
		// 				heb_name: "סונאטה",
		// 				eng_name: "sonata",
		// 				product_type: "תפרחת",
		// 				item_category: "T20 C4",
		// 				family: "היבריד",
		// 				marketer: "מדוקאן",
		// 				manufacturer: "מדוקאן",
		// 				manufacturer_series: "אדיסון",
		// 				parents: "גירל סקאוט קוקיז, פייס אוף אוגי",
		// 				series: "אינוביישן",
		// 				item_variant: "ישראל",
		// 				website: "Jane/iframe in cannabiz/Jane widget",
		// 				quantity: "1",
		// 			},
		// 		],
		// 	},
		// }
		window.dataLayer.push(event);
	};

	useEffect(() => {
		shouldTrack &&
			mixpanel.identify({
				email: claims?.email,
				id: claims?.id,
			});
	}, [claims?.email, shouldTrack]);

	useEffect(() => {
		const listener = event => handleClickEvent(event, collectAnalytics);

		document.addEventListener("click", listener);
		return () => {
			document.removeEventListener("click", listener);
		};
	}, [collectAnalytics]);

	return (
		<AnalyticsContext.Provider
			value={{
				collectAnalytics,
				collectAnalyticsGoogle,
			}}
		>
			{children}
		</AnalyticsContext.Provider>
	);
};

export { AnalyticsContext, AnalyticsProvider };
