import { Menu, Transition } from "@headlessui/react";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import Link from "next/link";
import React, { createRef, useMemo, useRef, useState } from "react";
import { classNames } from "util/common";

const HoverMenu = ({
	name,
	url = "",
	menuItems,
	isLinkAlsoAButton = true,
	className = "",
}) => {
	const [isHovering, setIsHovering] = useState(false);
	const buttonRef = useRef(null);
	const dropdownRef = useRef(null);
	const timeoutDuration = 0;
	let timeout;
	const openMenu = btnRef => btnRef?.current?.click();
	const closeMenu = dropdownRef =>
		dropdownRef?.current?.dispatchEvent(
			new KeyboardEvent("keydown", {
				key: "Escape",
				bubbles: true,
				cancelable: true,
			}),
		);

	const onMouseEnter = (closed, ref) => {
		setIsHovering(true);
		clearTimeout(timeout);
		closed && openMenu(ref);
	};
	const onMouseLeave = (open, ref) => {
		open &&
			(timeout = setTimeout(() => {
				closeMenu(ref);
				setIsHovering(false);
			}, timeoutDuration));
	};

	const subRefs = useMemo(() => {
		const subMenuItems = menuItems
			?.filter(item => item.subSubMenuItems?.map(si => si))
			.flat();
		return (
			subMenuItems?.map(() => {
				return createRef();
			}) ?? []
		);
	}, [menuItems]);

	function handleClosingOthers(id, currRef, menuOpen, isSubRef = false) {
		if (isSubRef) {
			const otherSubRefs = subRefs.filter(ref => {
				return ref?.current?.getAttribute("data-id") !== id;
			});

			otherSubRefs.forEach(ref => {
				const isOpen = ref?.current?.getAttribute("data-open") === "true";
				if (isOpen) {
					ref.current?.click();
				}
			});

			if (menuOpen) {
				currRef.current?.click();
			}

			return;
		}
	}
	return (
		<Menu
			as="div"
			className={`relative h-full flex flex-col justify-end cursor-pointer`}
		>
			{({ open, close }) => (
				<>
					<div
						className="flex items-center relative h-full"
						onClick={() => openMenu(open, buttonRef)}
						onMouseEnter={() => onMouseEnter(!open, buttonRef)}
						onMouseLeave={() => {
							handleClosingOthers(name, buttonRef, open);
							onMouseLeave(open, dropdownRef);
						}}
					>
						<Menu.Button
							ref={buttonRef}
							className={`inline-flex justify-center h-full  w-full font-medium leading-5 text-black transition duration-150 ease-in-out bg-header outline-none ring-0 ${
								isLinkAlsoAButton && "cursor-pointer"
							} ${className}`}
							as="div"
							data-open={open}
						>
							<Link href={isLinkAlsoAButton ? url : ""}>
								<a>
									<span
										className={`box-border text-base whitespace-nowrap flex h-full items-center px-4 border-b-2 border-transparent hover:bg-jane-100 ${
											!className.length && "hover:border-jane-500"
										} ${
											open &&
											isHovering &&
											!className.length &&
											"border-jane-500"
										}`}
									>
										{name}
									</span>
								</a>
							</Link>
						</Menu.Button>
					</div>

					<Transition
						show={open}
						enter="transition ease-out duration-10"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<Menu.Items
							ref={dropdownRef}
							onMouseEnter={() => onMouseEnter(!open, buttonRef)}
							onMouseLeave={close}
							className="absolute w-40 h-4 outline-none rounded-md ring-0"
						>
							<div className="w-40 bg-white border border-gray-200 rounded-md shadow-lg absolute right-0 top-[4px]">
								{menuItems.map((item, index) => {
									if (!item.subSubMenuItems) {
										return (
											<Menu.Item key={index}>
												{() => (
													<Link href={item.path}>
														<a
															className={`bg-white hover:bg-gray-100 border-l-4 border-transparent hover:border-jane-500 text-gray-900 flex justify-between w-full px-4 py-2 leading-5 text-left cursor-pointer ${
																index === 0 && "pt-3 rounded-t-md"
															}
																${index === menuItems.length - 1 && "rounded-b-md"}
																${item.isOpen && "border-jane-500 bg-gray-100"} `}
															onClick={() => {
																close();
																setIsHovering(false);
															}}
														>
															{item.label}
														</a>
													</Link>
												)}
											</Menu.Item>
										);
									} else {
										return (
											<Menu
												key={index}
												as="div"
												className={classNames(
													"relative h-full flex flex-col justify-end cursor-pointer",
													index === menuItems.length - 1 && "rounded-b-md",
												)}
											>
												{({ open: subMenuOpen }) => {
													return (
														<div
															className={`rounded-md flex items-center relative h-full`}
															onMouseEnter={() =>
																onMouseEnter(!subMenuOpen, subRefs[index])
															}
															onMouseLeave={() => {
																handleClosingOthers(
																	item.id,
																	subRefs[index],
																	subMenuOpen,
																	true,
																);
																setIsHovering(false);
															}}
														>
															<Menu.Button
																as="div"
																ref={subRefs[index]}
																className={` hover:bg-gray-100  text-gray-900 flex justify-between w-full pr-4 pl-2 py-2 leading-5 text-left cursor-pointer ${
																	index === 0 && "rounded-t-md pt-3"
																} 
																${item.isOpen && "border-jane-500 bg-gray-100"} `}
																data-id={item.path}
																data-open={subMenuOpen}
															>
																{item.path ? (
																	<Link href={item.path}>
																		<a
																			className="flex items-center w-full justify-between"
																			onClick={close}
																		>
																			{item.label}
																			<span className="w-4 aspect-square">
																				<ChevronLeftIcon />
																			</span>
																		</a>
																	</Link>
																) : (
																	<span
																		className={`flex items-center w-full justify-between`}
																	>
																		{item.label}
																		<span className="w-4 aspect-square">
																			<ChevronLeftIcon />
																		</span>
																	</span>
																)}
															</Menu.Button>
															<Transition
																show={subMenuOpen}
																enter="transition ease-out duration-10"
																enterFrom="transform opacity-0 scale-95"
																enterTo="transform opacity-100 scale-100"
																leave="transition ease-in duration-75"
																leaveFrom="transform opacity-100 scale-100"
																leaveTo="transform opacity-0 scale-95"
															>
																<Menu.Items className="absolute w-40 h-4 outline-none ring-0">
																	<div className="w-40 bg-white border border-gray-200 rounded-md shadow-lg absolute right-1 -top-[20px]">
																		{item.subSubMenuItems.map(
																			(subItem, idx, arr) => {
																				return (
																					<Menu.Item key={idx}>
																						{() => (
																							<>
																								<Link href={subItem.path}>
																									<a
																										className={`bg-white hover:bg-gray-100 border-l-4 border-transparent hover:border-jane-500 text-gray-900 flex justify-between w-full px-4 py-2 leading-5 text-left cursor-pointer ${
																											idx === 0 &&
																											"pt-3 rounded-t-md"
																										}
																										${idx === arr.length - 1 && "rounded-b-md"}
																										${subItem.isOpen && "border-jane-500 bg-gray-100"} ${
																											idx + 1 ===
																												item.subSubMenuItems
																													?.length -
																													1 && "pb-3"
																										}`}
																										onClick={() => {
																											close();
																										}}
																									>
																										{subItem.label}
																									</a>
																								</Link>
																							</>
																						)}
																					</Menu.Item>
																				);
																			},
																		)}
																	</div>
																</Menu.Items>
															</Transition>
														</div>
													);
												}}
											</Menu>
										);
									}
								})}
							</div>
						</Menu.Items>
					</Transition>
				</>
			)}
		</Menu>
	);
};

export default HoverMenu;
