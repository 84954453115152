import { FieldError } from "components/form/FieldError";
import React from "react";
import { classNames } from "util/common";
import { EMAIL_REGEX } from "./util/fieldsRegex";

function EmailField({
	register,
	errors,
	disabled = false,
	registerName = "email",
	placeholder = "כתובת אי-מייל",
	required = true,
	label = "אימייל",
	hideLabel = false,
	additionalValidation,
	className = "",
}) {
	return (
		<div className={className}>
			{!hideLabel && (
				<label
					htmlFor={registerName}
					className="block text-base font-medium text-gray-700"
				>
					{label}
				</label>
			)}
			<input
				type="email"
				id={registerName}
				className={classNames(
					"mt-1 block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-base focus:ring-1 disabled:cursor-not-allowed",
					!!errors?.[registerName]
						? "border-red-300 focus:ring-red-500 focus:border-red-500 bg-red-50 bg-opacity-50"
						: "border-gray-300 focus:ring-jane-500 focus:border-jane-500",
					disabled && "bg-jane-100",
				)}
				placeholder={placeholder}
				{...register(registerName, {
					required: required ? "שדה חובה" : required,
					pattern: {
						value: EMAIL_REGEX,
						message: "אנא הזן כתובת אי-מייל תקינה.",
					},
					...additionalValidation, // Additional custom validation rules
				})}
				disabled={disabled}
				autoComplete="off"
			/>
			{errors?.[registerName] && (
				<FieldError message={errors?.[registerName].message} />
			)}
		</div>
	);
}

export default EmailField;
