import { useApi } from "components/hooks/useApi";
import { createContext, useEffect, useRef, useState } from "react";

const initialState = {
	token: null,
};

const CsrfContext = createContext(initialState);

if (!CsrfContext) {
	throw new Error("CsrfContext is null");
}

const CsrfProvider = ({ children }) => {
	const api = useApi();
	const [ready, setReady] = useState(false);
	const [token, setToken] = useState(null);
	const fetching = useRef(false);

	useEffect(() => {
		try {
			void checkLogin();
		} catch (e) {
			console.log(e);
		}
	}, []);

	const checkLogin = async () => {
		try {
			if (!!fetching.current) {
				return;
			}
			fetching.current = true;
			const res = await api.getCsrfToken();
			fetching.current = false;
			if (res.ok) {
				setToken(res.data.t);
				setReady(true);
			} else {
				console.log("get csrf error", res.error);
			}
		} catch (e) {
			console.log(e);
		}
	};
	try {
		return (
			<CsrfContext.Provider
				value={{
					token,
				}}
			>
				{!!ready ? (
					children
				) : (
					<div className="mx-auto text-center py-24 text-gray-500">טוען...</div>
				)}
			</CsrfContext.Provider>
		);
	} catch (e) {
		console.log(e);
	}
};

export { CsrfContext, CsrfProvider };
