const urls = {
	test: "http://localhost:9040",
	development: "http://localhost:9040",
	production: "",
};

const janeConfig = {
	apiServerUrl: urls[process.env.NODE_ENV],
	localUrl: "http://localhost:3000",
	productionUrl: "https://jane.co.il",
	stagingUrl: "https://jane-stage.com",
	devUrl: "https://dev-jane.com",
	productionUrlLogin: "https://jane.co.il/login",
	devUrlLogin: "https://dev-jane.com/login",
	stagingUrlLogin: "https://jane-stage.com/login",
	microsoftClientId: "d543c318-7e50-4d84-b8c9-66d48933e504",
};

module.exports = janeConfig;
