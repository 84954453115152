import { useContext, useEffect, useState } from "react";
import { useApi } from "./useApi";
import { toastMessage } from "util/common";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { AuthContext } from "components/contexts/AuthContext";
import { LOGIN_STEPS } from "util/types";
import { useForm } from "react-hook-form";

export function useLogin(onLogin) {
	const { LOGIN, CODE_LOGIN, CODE_VERIFY, NOT_FOUND, REGISTER, WELCOME } =
		LOGIN_STEPS;
	const api = useApi();
	const { checkLogin } = useContext(AuthContext);
	const { collectAnalytics } = useContext(AnalyticsContext);
	const [step, setStep] = useState(LOGIN);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [seconds, setSeconds] = useState(30);
	const [canSendAgain, setCanSendAgain] = useState(false);
	const [attempt, setAttempt] = useState(1);

	const loginForm = useForm({
		defaultValues: { id_number: "", otp_code: "", signin_method: "phone" },
	});

	const { getValues, setValue, watch, trigger, handleSubmit } = loginForm;
	const registerForm = useForm({ mode: "onChange" });

	const tokenLogin = async token => {
		try {
			setLoading(true);
			const res = await api.submitLoginWithToken({ token });
			if (res.ok) {
				setStep(CODE_LOGIN);
				setValue("id_number", res.data.user_id);
			} else {
				throw res.error.message;
			}
		} catch (error) {
			console.log(error);
			toastMessage("שגיאה בהתחברות", "error");
		} finally {
			setLoading(false);
		}
	};

	const idLogin = async (signin_method = "phone") => {
		setSeconds(30);
		setCanSendAgain(false);
		try {
			setLoading(true);
			const res = await api.submitLogin({
				id_number: getValues("id_number"),
				signin_method: getValues("signin_method"),
			});
			if (res.ok) {
				setErrorMessage("");
				setStep(CODE_LOGIN);
			} else {
				throw res.error;
			}
		} catch (error) {
			if (error.message === "המשתמש אינו קיים.") {
				registerForm.setValue("id_number", getValues("id_number"));
				setStep(REGISTER);
			} else {
				setErrorMessage(error.message);
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		let interval = null;
		if (!canSendAgain && seconds > 0) {
			interval = setInterval(() => {
				setSeconds(seconds => seconds - 1);
			}, 1000);
		} else if (seconds === 0) {
			clearInterval(interval);
			setCanSendAgain(true);
		}
		return () => clearInterval(interval);
	}, [canSendAgain, seconds]);

	const sendAgain = () => {
		if (getValues("signin_method") === "phone") {
			if (attempt <= 1) {
				idLogin();
				setAttempt(attempt + 1);
				return;
			} else {
				setValue("signin_method", "whatsapp");
				setAttempt(1);
				idLogin();
				return;
			}
		}
		if (getValues("signin_method") === "whatsapp") {
			if (attempt <= 2) {
				idLogin();
				setAttempt(attempt + 1);
				return;
			}
		}
	};

	const registerUser = async params => {
		//*   params config:
		//    params: {
		//    id_number: "",
		//    email: "",
		//    phone_number: "",
		//		first_name: "",
		//		last_name: "",
		// }

		try {
			setLoading(true);
			const res = await api.submitSignup(params);
			if (res.ok) {
				toastMessage("הרשמה בוצעה בהצלחה", "success");
				checkLogin();
				if (typeof onLogin === "function") {
					onLogin();
				}
			} else {
				throw res.error;
			}
		} catch (error) {
			toastMessage(error.message, "error");
		} finally {
			setLoading(false);
		}
	};

	const verifyCodeLogin = async () => {
		// params conf:
		//     params: {
		//         otp_code: "",
		//         id_number: "",
		//     }

		try {
			setLoading(true);
			const res = await api.submitLoginOtp({
				otp_code: getValues("otp_code"),
				id_number: getValues("id_number"),
			});
			if (res.ok) {
				toastMessage(`התחברת לחשבונך בהצלחה`, "success");
				collectAnalytics("end_user_login", {
					method: "otp",
					otp_code: getValues("otp_code"),
					id_number: getValues("id_number"),
				});
				checkLogin();
				if (typeof onLogin === "function") {
					onLogin();
				}
			} else {
				throw res.error;
			}
		} catch (error) {
			toastMessage(error.message, "error");
		} finally {
			setLoading(false);
		}
	};

	const onKeyDown = e => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (step === LOGIN) {
				handleSubmit(idLogin)();
				return;
			}
			if (step === CODE_LOGIN) {
				verifyCodeLogin();
				return;
			}
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", onKeyDown);
		return () => {
			document.removeEventListener("keydown", onKeyDown);
		};
	}, [step]);

	return {
		step,
		loading,
		seconds,
		canSendAgain,
		setLoading,
		setStep,
		loginForm,
		registerForm,
		idLogin,
		tokenLogin,
		registerUser,
		verifyCodeLogin,
		sendAgain,
		attempt,
		//
		errorMessage,
		setErrorMessage,
		onLogin,
	};
}

//******************* DEAD CODE DO NOT DELETE *******************/
//!!!!!!!!!!!!!!!!! we will need this for new design  !!!!!!!!!!!!!!!!!/

//	const [userDetails, setUserDetails] = useState({});
//	const { authenticated, checkLogin, lastPath } = useContext(AuthContext);

// const verifyPhoneRequest = async params => {
// 	//* params config:
// 	//  params:{
// 	//      phone_number: ""
// 	//  }
// 	try {
// 		const res = await api.otpPhoneVerify(params);
// 		if (res.ok) {
// 			setStep(CODE_VERIFY);
// 			toastMessage("קוד אימות נשלח לטלפון שלך", "success");
// 			return;
// 		} else {
// 			throw res.error;
// 		}
// 	} catch (error) {}
// };

// const verifyPhoneRegister = async params => {
// 	//  params:{
// 	//      phone_number: ""
// 	//      otp_code: ""
// 	//  }
// 	try {
// 		const res = await api.otpVerifyPhoneSubmit(params);
// 		if (res.ok) {
// 			toastMessage("Phone verified!", "success");
// 			await registerUser(userInfo);
// 		} else {
// 			throw res.error;
// 		}
// 	} catch (error) {
// 		toastMessage(error.message, "error");
// 	}
// };

// const checkAvailability = async params => {
// 	//* params config:
// 	//  params:{
// 	//      id_number: "",
// 	//      email: "",
// 	//      phone_number: "",
// 	//  }
// 	try {
// 		const res = await api.checkAvailability(params);
// 		if (res.ok && !res.data.end_user_exists) {
// 			//available
// 			return true;
// 		} else {
// 			//not available
// 			throw res.error;
// 		}
// 	} catch (error) {
// 		return false;
// 	}
// };

// const startRegisterProcess = async params => {
// 	//*   params config:
// 	//    params: {
// 	//    id_number: "",
// 	//    email: "",
// 	//    phone_number: "",
// 	// }
// 	setUserDetails(params);
// 	try {
// 		const isAvailable = await checkAvailability(params);
// 		if (isAvailable) {
// 			await verifyPhoneRequest(params.phone_number);
// 		} else {
// 			throw { message: "אחד מהפרטים שהוזנו כבר בשימוש" };
// 		}
// 	} catch (error) {
// 		if (error.message === "אחד מהפרטים שהוזנו כבר בשימוש") {
// 			toastMessage(error.message, "error");
// 		}
// 		console.log(error.message);
// 	} finally {
// 		setLoading(false);
// 	}
// };
